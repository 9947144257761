import UserId from "./protocol/data/UserId";
import AuthMessage from "./protocol/messages/AuthMessage";
import BasicMessage from "./protocol/messages/BasicMessage";
import ResponseType from "./protocol/ResponseType";
import MessageType from "./protocol/messages/MessageType";
import GameOptions from "./protocol/data/GameOptions";
import DirectAction from "./protocol/data/DirectAction";
import Voting from "./protocol/data/Voting";
import VotingId from "./protocol/data/VotingId";
import VotingOption from "./protocol/data/VotingOption";
import RequestUsageOfDirectActionMessage from "./protocol/messages/RequestUsageOfDirectActionMessage";
import RequestVotePlacementMessage from "./protocol/messages/RequestVotePlacementMessage";
import TwitchAuthMessage from "./protocol/messages/TwitchAuthMessage";
import GlobalModel from "./GlobalModel";
import IWebsocketController from "./IWebsocketController";

class WebsocketController extends EventTarget implements IWebsocketController
{
    public static AUTHORIZATION_SUCCESSFUL_EVENT:string ="AuthorizationSuccessful";
    public static STREAMERS_DATA_RECEIVED_EVENTS:string ="StreamersDataReceivedEvent";
    public static MONEY_DATA_RECEIVED_EVENTS:string ="MoneyDataReceivedEvent";
    public static PAYMENT_LINK_RECEIVED_EVENTS:string ="PaymentLinkReceivedEvents";
    private Socket:WebSocket | null = null;
    private GlobalModel:GlobalModel;

    constructor(globalModel:GlobalModel) {
        super();
        this.GlobalModel = globalModel;
    }


    Connect = (url:string, onConnected:() => void) =>
    {
        this.Socket = new WebSocket(url);
        this.Socket.addEventListener('open', (e:any) =>
        {
            console.log("Connection established");
            onConnected();
        });
        this.Socket.addEventListener('message', this.OnMessageFromServer);
    }

    OnMessageFromServer = (event:MessageEvent<any>) =>
    {
        const PING_TEXT = 'ping';
        const PONG_RESPONSE = 'pong';
        //console.log('Message from server ', event.data);

        if ( event.data === PING_TEXT)
        {
            this.Socket?.send(PONG_RESPONSE);
            return;
        }

        let response = JSON.parse(event.data);
        if ( response.ResponseType === ResponseType.AuthorizationSuccessfull)
        {

            console.log(WebsocketController.AUTHORIZATION_SUCCESSFUL_EVENT)
            this.dispatchEvent(new Event(WebsocketController.AUTHORIZATION_SUCCESSFUL_EVENT));
        }
        else if ( response.ResponseType === ResponseType.PlayerOptions)
        {
            //console.log("Player options received")
            let playerOptionsResponseObject = JSON.parse(response.Payload);

            let gameOptionsJSON = playerOptionsResponseObject["GameOptions"];
            let gameOptions:Map<string, GameOptions> = this.ParsePlayerOptions(gameOptionsJSON);
            this.GlobalModel.LatestPlayerToGameOptions = gameOptions;
            //console.log("Parsed game options " + JSON.stringify(gameOptions));

            this.dispatchEvent(new CustomEvent(WebsocketController.STREAMERS_DATA_RECEIVED_EVENTS, {detail:gameOptions}));
        }
        else if ( response.ResponseType === ResponseType.CashUpdateResponse)
        {
            //console.log("Cash update received received")
            let cashUpdateObject = JSON.parse(response.Payload);
            let cash = parseInt(cashUpdateObject["Cash"]);
            let secondsBeforeNextUpdate = parseInt(cashUpdateObject["SecondsBeforeNextUpdate"]);
            this.GlobalModel.UpdateCashRequestTime(secondsBeforeNextUpdate);
            this.GlobalModel.UpdateCash(cash);
            this.GlobalModel.SetCashRequestInProgress(false);
            //console.log("Current player cash " + cash)
            this.dispatchEvent(new CustomEvent(WebsocketController.MONEY_DATA_RECEIVED_EVENTS, {detail:cash}))
            //console.log("Current player cash in locator is " + Locator.Model.CurrentCash)
        } else if ( response.ResponseType == ResponseType.PaymentLinkReceivedResponse ) {
            let paymentLinkResponse = JSON.parse(response.Payload);
            this.dispatchEvent(new CustomEvent(WebsocketController.PAYMENT_LINK_RECEIVED_EVENTS, {detail:paymentLinkResponse["URL"]}))
        }
    }

    ParsePlayerOptions = (gameOptionsJSON:{ [index: string] : { DirectActions:DirectAction[], Votings:Voting[] }} ):Map<string, GameOptions> =>
    {
        let playerToGameOptions = new Map<string, GameOptions>();
        for (let key in gameOptionsJSON)
        {
            //First level Streamer
            let streamerOptions = gameOptionsJSON[key];
            if (streamerOptions === undefined) {
                continue;
            }
            let gameOptions = new GameOptions();
            gameOptions.DirectActions = [];

            for (let directActionKey in streamerOptions.DirectActions)
            {
                let directActionJson = streamerOptions.DirectActions[directActionKey];

                let directAction = new DirectAction();
                directAction.CooldownSec = directActionJson.CooldownSec;
                directAction.Description = directActionJson.Description;
                directAction.Name = directActionJson.Name;
                directAction.SoftPrice = directActionJson.SoftPrice;
                directAction.HardPrice = directActionJson.HardPrice;

                gameOptions.DirectActions.push(directAction);
                //console.log("DirectAction " + JSON.stringify(directAction));
            }

            gameOptions.Votings = [];
            for (let votingKey in streamerOptions.Votings)
            {
                let votingJson = streamerOptions.Votings[votingKey];
                let voting = new Voting();
                let votingId = new VotingId();
                votingId.Id = votingJson.Id.Id;
                voting.Id = votingId;
                voting.Name = votingJson.Name;
                voting.VotingDurationSeconds = votingJson.VotingDurationSeconds;
                voting.LocalTimeWhenVotingWasReceived = new Date();

                let votingOptions = [];
                for (let votingOptionKey in votingJson.VotingOptions)
                {
                    let votingOptionJson = votingJson.VotingOptions[votingOptionKey];
                    let votingOption = new VotingOption();
                    votingOption.Id = votingOptionJson.Id;
                    votingOption.Name = votingOptionJson.Name;
                    votingOption.ImageURL = votingOptionJson.ImageURL;
                    votingOptions.push(votingOption)
                }
                voting.VotingOptions = votingOptions;
                voting.HasVoteBeenCast = votingJson.HasVoteBeenCast;
                //console.log("Voting " + JSON.stringify(voting));

                gameOptions.Votings.push(voting);
            }

            playerToGameOptions.set(key, gameOptions);
        }

        return playerToGameOptions;
    }

    Authorize = (login:string, password:string) =>
    {
        let userId = new UserId();
        userId.Id = login;

        let authMessage = new AuthMessage();
        authMessage.UserId = userId
        authMessage.Token = password;

        let message = new BasicMessage();
        message.MessageType = MessageType.Auth;
        message.Payload = JSON.stringify(authMessage);

        this.Socket?.send(JSON.stringify(message));
    }

    TwitchAuthorize = (login:string, token:string) =>
    {
        let userId = new UserId();
        userId.Id = login;

        let authMessage = new TwitchAuthMessage();
        authMessage.UserId = userId
        authMessage.Token = token;

        let message = new BasicMessage();
        message.MessageType = MessageType.TwitchAuth;
        message.Payload = JSON.stringify(authMessage);

        this.Socket?.send(JSON.stringify(message));
    }

    RequestOptionsForPlayers = () =>
    {
        let message = new BasicMessage();
        message.MessageType = MessageType.RequestGameOptionsForAll;
        message.Payload = '';

        this.Socket?.send(JSON.stringify(message));
    }

    UseGameOption = (streamerId:string, gameOptionId:string) =>
    {
        let request = new RequestUsageOfDirectActionMessage();
        request.PlayerId = new UserId()
        request.PlayerId.Id = streamerId;
        request.GameOptionsId = gameOptionId;

        let message = new BasicMessage();
        message.MessageType = MessageType.RequestUsageOfDirectAction;
        message.Payload = JSON.stringify(request);

        this.Socket?.send(JSON.stringify(message));
    }

    RequestMoneyBoost = () =>
    {
        let message = new BasicMessage();
        message.MessageType = MessageType.RequestMoneyBoost;
        this.Socket?.send(JSON.stringify(message));
        this.GlobalModel.SetCashRequestInProgress(true);
    }

    PlaceVote = (streamerId:string, votingId:VotingId, votingOptionId:string) =>
    {
        console.log(`Websocket controller: Place vote ${streamerId}. ${votingId}. ${votingOptionId}`)
        let request = new RequestVotePlacementMessage();
        request.PlayerId = new UserId();
        request.PlayerId.Id = streamerId;
        request.VotingId = votingId;
        request.VoteOptionId = votingOptionId;

        let message = new BasicMessage();
        message.MessageType = MessageType.RequestVotePlacement;
        message.Payload = JSON.stringify(request);
        this.Socket?.send(JSON.stringify(message));

        this.GlobalModel.PlaceVote(streamerId, votingId, votingOptionId)
    }

}

export default WebsocketController;