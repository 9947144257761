import VotingId from "./VotingId";
import VotingOption from "./VotingOption";

class Voting
{
    /// <summary>
    ///  Id of the voting - Game should decide on the id it will be able to interpret.
    /// </summary>
    public Id:VotingId = new VotingId();
    /// <summary>
    ///  English name of the voting.
    /// </summary>
    public Name:string = "";
    /// <summary>
    ///  Local time when voting was received
    /// </summary>
    public LocalTimeWhenVotingWasReceived:Date = new Date();
    /// <summary>
    ///  Voting duration in seconds
    /// </summary>
    public VotingDurationSeconds:number = 0;
    /// <summary>
    /// List of voting options available within the voting.
    /// </summary>
    public VotingOptions:VotingOption[] = [];
    /// <summary>
    /// Has the voting already occurred
    /// </summary>
    public HasVoteBeenCast:boolean = false;
}

export default Voting;