import React, {useEffect, useState} from 'react'
import WebsocketController from "../WebsocketController";
import TwitchGameButtonComponent from "../components/Twitch/TwitchGameButtonComponent";
import GlobalModel from "../GlobalModel";
import TwitchVoteComponent from "../components/Twitch/TwitchVoteComponent";
import Voting from "../protocol/data/Voting";
import DirectAction from "../protocol/data/DirectAction";
import GameOptions from "../protocol/data/GameOptions";
import IWebsocketController from "../IWebsocketController";
import { useTranslation } from 'react-i18next';

const TwitchStreamerOptionsSelection = (p:{ websocketController:IWebsocketController,
                                       globalModel:GlobalModel,
                                       selectedStreamerId:string,
                                       streamerName:string, viewerId:string}) => {
    const [selectedActionIndex, setSelectedActionIndex] = useState(-1);
    const [playerToGameOptions, setPlayerToGameOptions] = useState(new Map<string, GameOptions>());
    const [playerCash, setPlayerCash] = useState(p.globalModel.CurrentCash);
    const [cashRequestInProgress, setCashRequestInProgress] = useState(p.globalModel.CashRequestInProgress);
    const [cashRequestHasTimeout, setCashRequestHasTimeout] = useState(false);
    const [cashRequestTimerId, setCashRequestTimerId] = useState(0);
    const [forceUpdateTrigger, setForceUpdateTrigger] = useState(false);
    const { t, i18n } = useTranslation();

    const OnStreamersDataReceived = () => {
        console.log("Game options updated while streamer page was active " +
            JSON.stringify(p.globalModel.LatestPlayerToGameOptions))
        setPlayerToGameOptions(p.globalModel.LatestPlayerToGameOptions);
    }

    const OnVotePlacedEvent = () => {
        console.log("TwitchStreamerOptionsPage: Vote placed " +
            JSON.stringify(p.globalModel.LatestPlayerToGameOptions))
        setPlayerToGameOptions(p.globalModel.LatestPlayerToGameOptions);
        forceUpdate();
    }

    const forceUpdate = () => setForceUpdateTrigger(!forceUpdateTrigger);

    const OnMoneyUpdateReceived = (_:any) => {
        console.log("OnMoneyUpdateReceived " + p.globalModel.SecondsBeforeNextUpdate + " " + p.globalModel.CashRequestInProgress)
        setPlayerCash(p.globalModel.CurrentCash)
    }

    const OnCashRequestStatusChanged = (_:any) => {
        console.log("OnCashRequestStatusChanged " + p.globalModel.SecondsBeforeNextUpdate + " " + p.globalModel.CashRequestInProgress)
        setCashRequestInProgress(p.globalModel.CashRequestInProgress)
    }

    const OnCashRequestTimeoutChanged = (_:any) => {
        console.log("OnCashRequestTimeoutChanged " + p.globalModel.SecondsBeforeNextUpdate + " " + p.globalModel.CashRequestInProgress)
        setCashRequestHasTimeout(p.globalModel.SecondsBeforeNextUpdate > 0);
        clearTimeout(cashRequestTimerId);
        setCashRequestTimerId(setTimeout(_ => {
            setCashRequestHasTimeout(false);
        }, p.globalModel.SecondsBeforeNextUpdate * 1000));
    }

    function gtagEvent(eventName:string, args:any) {
        (window as any).gtag("event", eventName, args)
    }

    useEffect(() => {
        p.websocketController.addEventListener(WebsocketController.STREAMERS_DATA_RECEIVED_EVENTS, OnStreamersDataReceived);
        p.globalModel.addEventListener(GlobalModel.VOTE_PLACED, OnVotePlacedEvent);
        p.globalModel.addEventListener(GlobalModel.CASH_VALUE_UPDATED, OnMoneyUpdateReceived);
        p.globalModel.addEventListener(GlobalModel.CASH_REQUEST_STATUS_CHANGED, OnCashRequestStatusChanged);
        p.globalModel.addEventListener(GlobalModel.CASH_REQUEST_TIMEOUT_UPDATED, OnCashRequestTimeoutChanged);

        gtagEvent('web/stream_open', {
            'ID_streamer': `${p.streamerName}`
        });
        setPlayerToGameOptions(p.globalModel.LatestPlayerToGameOptions);
        return function cleanup() {
            p.websocketController.removeEventListener(WebsocketController.STREAMERS_DATA_RECEIVED_EVENTS, OnStreamersDataReceived);
            p.globalModel.removeEventListener(GlobalModel.VOTE_PLACED, OnVotePlacedEvent);
            p.globalModel.removeEventListener(GlobalModel.CASH_VALUE_UPDATED, OnMoneyUpdateReceived);
            p.globalModel.removeEventListener(GlobalModel.CASH_REQUEST_STATUS_CHANGED, OnCashRequestStatusChanged);
            p.globalModel.removeEventListener(GlobalModel.CASH_REQUEST_TIMEOUT_UPDATED, OnCashRequestTimeoutChanged);
        }
    }, []);

    let directActionsValue:DirectAction[] = [];
    let votingsValue:Voting[] = [];
    console.log("Selected streamer Id " + p.selectedStreamerId);
    if (playerToGameOptions.has(p.selectedStreamerId) ) {
        const gameOptions = playerToGameOptions.get(p.selectedStreamerId);
        console.log("Selected streamer Id game options" + gameOptions);
        if ( gameOptions !== undefined) {
            directActionsValue = gameOptions.DirectActions;
            votingsValue = gameOptions.Votings;
        }
    }

    let coinsBoost = 1000;

    let getMoneyButton = <TwitchGameButtonComponent
        text={t("GetBonusSoftCurrency", { coinsValue: coinsBoost.toString() } ) }
        isDisabled={cashRequestInProgress ||  cashRequestHasTimeout}
        onClick={() => {
            gtagEvent(`web/action_getCoins`, null);
            p.websocketController.RequestMoneyBoost();
        }}
        key={"getCoinsButton"}/>


    let playerVotingsSelectedList = votingsValue.map ((x:Voting, _:number) => {
        let elapsedSeconds = Math.floor((new Date().getTime() - x.LocalTimeWhenVotingWasReceived.getTime()) / 1000);
        let remainingTimeSeconds = x.VotingDurationSeconds - elapsedSeconds;
        remainingTimeSeconds = Math.max(remainingTimeSeconds, 0);
        return < TwitchVoteComponent
            key = {x.Id.Id}
            remainingTimeSeconds = {remainingTimeSeconds}
            streamerId = {p.selectedStreamerId}
            viewerId={p.viewerId}
            votingId = {x.Id}
            votingOptions = {x.VotingOptions}
            hasVoteBeenCast = {x.HasVoteBeenCast}
        />
    });

    let playerOptionsSelectedList = directActionsValue.map ((x:DirectAction, index:number) =>
    {
        let priceText =t(x.Name) + " ";
        priceText += x.HardPrice > 0 ? x.HardPrice + " RUB" : x.SoftPrice + " Coins";
        return <TwitchGameButtonComponent
            isDisabled={p.globalModel.CurrentCash < directActionsValue[index].SoftPrice}
            key={x.Name}
            text={ priceText}
            onClick={ () => {
                const action = directActionsValue[index];
                gtagEvent(`web/action_${action.Name}`, {
                    'ID_streamer':  `${p.selectedStreamerId}`,
                    'ID_viewer':  `${p.viewerId}`,
                    'soft_cost':  `${action.SoftPrice}`,
                    'hard_cost':  `${action.HardPrice}`
                });

                //TODO: Time to introduce Action abstraction to avoid
                //TODO: transaction code lying around in the view
                //TODO: Better cohesion will be achieved through this approach
                p.websocketController.UseGameOption(p.selectedStreamerId, action.Name);
                p.globalModel.UpdateCash(p.globalModel.CurrentCash - action.SoftPrice);
                setSelectedActionIndex(index);
            } }
        />
    });

    return (<div>
        <div className="container unselectable">
            <img src="./images/Coin_border.png" alt="coins"/>
            <div className="centered">
                {t("CurrentCoins", { coinsValue: playerCash.toString() } ) }
            </div>
        </div>
        {playerOptionsSelectedList}
        { getMoneyButton }
        {playerVotingsSelectedList}
    </div>);
}

export default TwitchStreamerOptionsSelection;