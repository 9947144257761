import DirectAction from "./DirectAction";
import Voting from "./Voting";

class GameOptions
{
    /// <summary>
    /// List of direct actions which could be invoked in game by viewers.
    /// </summary>
    public DirectActions:DirectAction[] = [];
    /// <summary>
    /// List of votings, currently in progress.
    /// </summary>
    public Votings:Voting[] = [];
}

export default GameOptions;