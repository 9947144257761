enum MessageType {
    Auth,
    GameOptionsUpdate,
    GameStatusUpdate,
    RequestGameOptionsForAll,
    RequestGameOptionsForPlayer,
    RequestUsageOfDirectAction,
    RequestVotePlacement,
    RequestMoneyBoost,
    TwitchAuth
}

export default MessageType;