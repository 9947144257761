import React from 'react'

const GameOptionComponent = (p:{optionImageUrl:string, optionName:string, optionPrice:number,
                             isSelected:boolean, isDisabled:boolean, onClick:() => void}) => {
    let backgroundColorTag = p.isSelected ? 'pr_card pr_card_checked' : "pr_card";
    return (
        <label className={backgroundColorTag} onClick={() => {
        if (!p.isDisabled)
        {
            p.onClick()
        }
    }}>
    <span className="img">
    <img src={p.optionImageUrl} alt="v2"/>
        </span>

        <span className="desc">
    <span className="name">
        {p.optionName}
        </span>

        <span className="price price_coin">
        {p.optionPrice}
        </span>
        </span>
        </label>)
}

export default GameOptionComponent;