import React, {useEffect, useState} from 'react'

const TwitchGameButtonComponent = (p:{text:string, isDisabled:boolean, onClick:() => void}) => {
    const [isClicked, setIsClicked] = useState(false);
    const [isOver, setIsOver] = useState(false);

    let handleWindowUp = (e:any) => setIsClicked(false);

    useEffect(() => {
        window.addEventListener("mouseup", handleWindowUp);

        return function cleanup() {
            window.removeEventListener("mouseup", handleWindowUp);
        }
    }, []);

    let getImage = () => {
        if (p.isDisabled) {
            return "images/button_border_disabled.png"
        }

        if ( isClicked) {
            return "images/button_border_pressed.png";
        }
        if ( isOver) {
            return "images/button_border_light.png";
        }
        return "images/button_border.png";
    }

    let handleButtonDown = () => setIsClicked(true);
    let handleButtonUp = () => setIsClicked(false);
    let handleMouseEnter= () => setIsOver(true);
    let handleMouseLeave= () => setIsOver(false);

    return (<div className="container unselectable" draggable="false" onClick={() => {
        if (!p.isDisabled)
        {
            p.onClick()
        }
    }}
                 onMouseDown={handleButtonDown}
                 onMouseUp={handleButtonUp}
                 onMouseEnter ={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}>
        <img src={getImage()}
             draggable="false"
             alt="button options" />
        <div className="centered_button">
            {p.text}
        </div>
    </div>)
}

export default TwitchGameButtonComponent;