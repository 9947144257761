import React, {useEffect, useState} from 'react'
import Locator from "../../Locator";
import TwitchVoteOptionComponent from "./TwitchVoteOptionComponent";
import { useTranslation } from 'react-i18next';
import VotingOption from "../../protocol/data/VotingOption";
import VotingId from "../../protocol/data/VotingId";
import TimeUtils from "../../utils/TimeUtils";

const TwitchVoteComponent = (p:{remainingTimeSeconds:number, votingOptions:VotingOption[],
                             streamerId:string, viewerId:string, votingId:VotingId, hasVoteBeenCast:boolean}) => {
    const [timeLeft, setTimeLeft] = useState(p.remainingTimeSeconds);
    const [voteCast, setVoteCast] = useState(p.hasVoteBeenCast);
    const { t, i18n } = useTranslation();

    const _viewerId = p.viewerId;
    useEffect(() => {
        if ( timeLeft <= 0)
        {
            setVoteCast(true);
            return;
        }
        const timer = setTimeout(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return function cleanup() {
            clearTimeout(timer);
        }
    });

    function gtagEvent(eventName:string, args:any) {
        (window as any).gtag("event", eventName, args)
    }

    function placeVote(x:VotingId) {
        gtagEvent( `web/voting`, {
            'ID_streamer':  `${p.streamerId}`,
            'ID_viewer':  `${_viewerId}`,
            'voting_option':  `${x.Id}`
        });
        Locator.Socket.PlaceVote(p.streamerId, p.votingId, x.Id);
        setVoteCast(true);
    }


    let isDisabled = voteCast || timeLeft <= 0;
    let votingOptionsHtml = p.votingOptions.map((x, index) => {
        return <TwitchVoteOptionComponent header={t("Option") + " " + index}
                                          isDisabled={isDisabled}
                                          choiceText={t(x.Name)}
                                          onClick={() => {
                                              if (!isDisabled) {
                                                  placeVote(x);
                                              }
                                          }
                                          }/>
    });
    let votingOutputHTML = (<table><tbody><tr>{votingOptionsHtml}</tr></tbody></table>);

    return (<div>
        <div className="container unselectable" draggable="false">
            <div className="centered_button">{TimeUtils.FormatTime(timeLeft)}</div></div>
        {votingOutputHTML}
    </div>)
}

export default TwitchVoteComponent;