class VotingOption
{
    /// <summary>
    /// Id of the vote option - Game should decide on the id it will be able to interpret.
    /// </summary>
    public Id:string = "0";

    /// <summary>
    /// English name of the voting option.
    /// </summary>
    public Name:string = "";
    /// <summary>
    /// Image URL for the option to be used by the client
    /// </summary>
    public ImageURL:string = "";
}

export default VotingOption;