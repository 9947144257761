import UserId from "../data/UserId";
import VotingId from "../data/VotingId";

class RequestVotePlacementMessage
{
    public PlayerId:UserId = new UserId();
    public VotingId:VotingId = new VotingId();
    public VoteOptionId:string = '';
}

export default RequestVotePlacementMessage;