import GlobalModel from "./GlobalModel";
import WebsocketController from "./WebsocketController";
import GlobalMessageBus from "./GlobalMessageBus";

class Locator
{
    public static GlobalMessageBus:GlobalMessageBus;
    public static Model:GlobalModel;

    public static Socket:WebsocketController;
    public static Port:number = 2243;
    public static ProtocolType:string = 'wss';
    public static Url:string = 'actionstream.xyz';
    public static PathToEndpoint:string = 'ws_viewver';

    public static Twitch:any;
    public static IsTwitchLogin:boolean = false;
}

export default Locator;