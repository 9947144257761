import VotingId from "./protocol/data/VotingId";
import GameOptions from "./protocol/data/GameOptions";

class GlobalModel extends EventTarget
{
    static CASH_REQUEST_TIMEOUT_UPDATED:string ="CashRequestTimestampUpdated";
    static CASH_REQUEST_STATUS_CHANGED:string ="CashRequestStatusChanged";
    static CASH_VALUE_UPDATED:string ="CashValueUpdated";
    static VOTE_PLACED:string ="VotePlacedEvent";

    public CurrentCash:number = 0;
    public CashRequestInProgress:boolean = false;
    public SecondsBeforeNextUpdate:number = 0;
    public SelectedStreamerId:string = "";
    public ViewerId:string = "";
    public LatestPlayerToGameOptions:Map<string, GameOptions> = new Map<string, GameOptions>();

    UpdateCash = (coins:number) => {
        this.CurrentCash = coins;
        this.dispatchEvent(new Event(GlobalModel.CASH_VALUE_UPDATED));
    }

    SetCashRequestInProgress = (isInProgress:boolean) => {
        this.CashRequestInProgress = isInProgress;
        this.dispatchEvent(new Event(GlobalModel.CASH_REQUEST_STATUS_CHANGED));
    }

    UpdateCashRequestTime = (secondsBeforeNextUpdate:number) => {
        this.SecondsBeforeNextUpdate = secondsBeforeNextUpdate;
        this.dispatchEvent(new Event(GlobalModel.CASH_REQUEST_TIMEOUT_UPDATED));
    }

    PlaceVote = (streamerId:string, votingId:VotingId, votingOptionId:string) => {
        console.log(`GlobalModel: Vote placed ${streamerId} ${votingId.Id}`);
        console.log(JSON.stringify(this.LatestPlayerToGameOptions.get(streamerId)?.Votings));
        let gameOptions =this.LatestPlayerToGameOptions.get(streamerId);
        if (gameOptions === undefined) {
            return;
        }
        let votings = gameOptions.Votings;
        let voting = votings.find( x => x.Id.Id === votingId.Id);
        if ( voting === undefined) {
            return;
        }
        voting.HasVoteBeenCast = true;
        this.dispatchEvent(new Event(GlobalModel.VOTE_PLACED))
    };
}
export default GlobalModel;