class TimeUtils
{
    public static FormatTime(seconds:number):string {
        const format = (v:number) => `0${Math.floor(v)}`.slice(-2)
        const hours = seconds / 3600
        const minutes = (seconds % 3600) / 60

        return [hours, minutes, seconds % 60].map(format).join(':')
    }

}

export default TimeUtils;