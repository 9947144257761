import React, {useEffect, useState} from 'react'

const TwitchVoteOptionComponent = (p:{header:string, choiceText:string, onClick:() => void, isDisabled:boolean}) => {
    const [isClicked, setIsClicked] = useState(false);
    const [isOver, setIsOver] = useState(false);

    let handleWindowUp = (e:any) => setIsClicked(false);

    useEffect(() => {
        window.addEventListener("mouseup", handleWindowUp);

        return function cleanup() {
            window.removeEventListener("mouseup", handleWindowUp);
        }
    }, []);

    let getImage = () => {
        if (p.isDisabled) {
            return "images/vote_border_200_disabled.png"
        }

        if ( isClicked) {
            return "images/vote_border_200_pressed.png";
        }
        if ( isOver) {
            return "images/vote_border_200_light.png";
        }
        return "images/vote_border_200.png";
    }

    let handleButtonDown = () => setIsClicked(true);
    let handleButtonUp = () => setIsClicked(false);
    let handleMouseEnter= () => setIsOver(true);
    let handleMouseLeave= () => setIsOver(false);

    return (<th><div className="container_vote" draggable="false" onClick={() => p.onClick() }
                     onMouseDown={handleButtonDown}
                     onMouseUp={handleButtonUp}
                     onMouseEnter={handleMouseEnter}
                     onMouseLeave={handleMouseLeave}>
        <img src={getImage()}
             draggable="false"
             alt="button options" width="199"/>
        <div className="centered_vote">
            {p.header}
        </div>
        <div className="vote_option">
            <div className="long_text">
                {p.choiceText}
            </div>
        </div>
    </div></th>);

}

export default TwitchVoteOptionComponent;