import React from 'react'

const GameVotingOptionComponent = (p:{imageUrl:string, optionName:string,
                                   isChecked:boolean, isDisabled:boolean, onClick:() => void}) => {
    const classNameValue = p.isDisabled ? "no_vote"
        : p.isChecked ? "checked" : "";
    const checkedImg = p.isChecked ? (<span className="check_icon">
                <img src="images/check2.svg" alt="check2"/>
            </span>) : "";

    return (<div className="var_card" onClick={() => {
        console.log("Voting button pressed")
        if (!p.isDisabled) {
            p.onClick();
        }
    }}>
        <label className= {classNameValue}>
            <img src={p.imageUrl} alt="v1"/>
            <span className="txt">
                {p.optionName}
            </span>
            {checkedImg}
        </label>
    </div>)
}

export default GameVotingOptionComponent;