enum ResponseType {
    UserNotAuthorized,
    AuthorizationSuccessfull,
    PlayerOptions,
    PlayerStatus,

    NewGameAdded,
    GameRemoved,

    UseGameOption,
    GameOptionCooldownInProgress,

    VotingComplete,
    VotingIsNoLongerActive,
    VotingOptionsHasBeenSelected,
    VotingOptionIsInvalid,
    VotingSuccessful,

    CashUpdateResponse,
    MessageSentResponse,

    PaymentLinkReceivedResponse
}

export default ResponseType;