import React from 'react'
import Locator from "../../Locator";

const StreamerHeaderComponent = (p:{streamerName:string, viewerName:string,
                                 moneyAmount:number, onBackButtonClicked:() => void}) => {

    return (<div id="header" className="header">
        <div className="mw">
            <a className="back" onClick={(x) => p.onBackButtonClicked()}>
                <img src="images/chevron_left.svg" alt="chevron_left"/>
            </a>
            <div className="logo">
                <img src="images/action_stream.jpg" alt="action_stream"></img>
            </div>

            <div className="streamer">
                <div className="img">
                    <img src="images/d.png" alt="d"/>
                </div>

                <div className="name">
                    {p.streamerName}
                </div>
            </div>
            <div className="name_line">
                <label>Имя:</label>

                <div className="name">
                    {p.viewerName}
                </div>
            </div>
            <div className="money_line">
                <button onClick={(e) => {
                    Locator.Socket.RequestMoneyBoost();
                }}>Request money</button>

                <div className="money">
                    {p.moneyAmount}
                </div>
            </div>
        </div>
    </div>)
}

export default StreamerHeaderComponent;