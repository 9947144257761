import React, {useEffect, useState} from "react";
import Locator from "../Locator";
import WebsocketController from "../WebsocketController";
import GlobalMessageBus from "../GlobalMessageBus";
import StreamersSelectionStreamerComponent from "../components/StandardWeb/StreamersSelectionStreamerComponent";
import GameOptions from "../protocol/data/GameOptions";

const StreamerSelectionPage = () =>
{
    const [playerToGameOptions, setPlayerToGameOptions] = useState(new Map<string, GameOptions>());

    const OnStreamersDataReceived = () => {
        const latestPlayerGameOptions = Locator.Model.LatestPlayerToGameOptions;
        console.log("Game options updated while streamer page was active " + JSON.stringify(latestPlayerGameOptions))
        setPlayerToGameOptions(latestPlayerGameOptions);
    }

    useEffect(() => {
        const latestPlayerGameOptions = Locator.Model.LatestPlayerToGameOptions;
        Locator.Socket.addEventListener(WebsocketController.STREAMERS_DATA_RECEIVED_EVENTS, OnStreamersDataReceived );
        setPlayerToGameOptions(latestPlayerGameOptions);
        return function cleanup() {
            Locator.Socket.removeEventListener(WebsocketController.STREAMERS_DATA_RECEIVED_EVENTS, OnStreamersDataReceived );
        }
    }, []);



    let playerSelectionList = Array.from(playerToGameOptions.entries(), (item, index) =>
    {
        let key = item[0];
        return <StreamersSelectionStreamerComponent
            key={key}
            streamerName={key}
            streamerImageUrl={"images/s1.png"}
            index={index}
            onClickCallback={(index => {
                Locator.Model.SelectedStreamerId = key;
                Locator.GlobalMessageBus.dispatchEvent(new Event(GlobalMessageBus.STREAMER_SELECTED_EVENT));
            })}/>
    });

    return (<div className="page">
        <div className="streamers">
            <div className="mw">
                <div className="cont">
                    <div className="top_cont">
                        <div className="logo">
                            <img src="images/action_stream.jpg" alt="action_stream"></img>
                        </div>
                    </div>
                    <div className="broadcasts">
                        <h5 className="h5">
                            Трансляции:
                        </h5>
                        <div className="broadcasts_list">
                            <ul>{playerSelectionList}</ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);

}

export default StreamerSelectionPage;