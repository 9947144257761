export const getLocalizationResources = () => {
    return {
        en: {
            translation: {
                "GetBonusSoftCurrency": "Get {{coinsValue}} coins",
                "CurrentCoins": "You have: {{coinsValue}} coins",
                "SpawnHealth": "Spawn health",
                "SpawnWeapon": "Spawn weapon",
                "SpawnItemWithCooldown": "Spawn item with cooldown",
                "Option": "Option",
                "DropAmmo": "DROP AMMO",
                "DropRage": "DONATE",
                "MeteorRain": "METEOR RAIN",
                "SummonLootEnemy": "FAT LOOT",
                "SummonMinions": "DEVIL HELP",
                "Wiki_GabenName": "GABEN",
                "ActorId_Behemoth": "BEHEMOTH",
                "ActorId_BOverlord": "BLACK OVERLORD",
                "ActorId_BigWack": "BIG WAC",
                "ActorId_Nerdomancer": "NERDOMANCER",
                "ActorId_ElRoico": "EL ROICO",
                "Option 0": "Variant 1",
                "Option 1": "Variant 2"
            }
        },
        ru: {
            translation: {
                "GetBonusSoftCurrency": "Получить {{coinsValue}} монет",
                "CurrentCoins": "У вас: {{coinsValue}} монет",
                "SpawnHealth": "Выдать аптечку",
                "SpawnWeapon": "Выдать оружие",
                "SpawnItemWithCooldown": "Выдать айтем с таймаутом",
                "Option": "Вариант",
                "DropAmmo": "Дать патроны",
                "DropRage": "Сфера ярости",
                "MeteorRain": "Метеоритный дождь",
                "SummonLootEnemy": "Жирный лут",
                "SummonMinions": "Чертова помощь",
                "Wiki_GabenName": "GABEN",
                "ActorId_Behemoth": "BEHEMOTH",
                "ActorId_BOverlord": "BLACK OVERLORD",
                "ActorId_BigWack": "BIG WAC",
                "ActorId_Nerdomancer": "NERDOMANCER",
                "ActorId_ElRoico": "EL ROICO",
                "Option 0": "Вариант 1",
                "Option 1": "Вариант 2"
            }
        }
    };
}

export default getLocalizationResources;