import React from 'react'

const StreamersSelectionStreamerComponent = (p:{streamerName:string, streamerImageUrl:string,
                                             index:number, onClickCallback:(i:number) => void}) => {
    return (<li key={p.streamerName}
                onClick={(selected) => p.onClickCallback(p.index)}>
        <a href="#">
            <div className="img">
                <img src={p.streamerImageUrl} alt="s1"/>
            </div>

            <div className="name">
                {p.streamerName}
            </div>
        </a>
    </li>)
}

export default StreamersSelectionStreamerComponent;