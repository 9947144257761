import React, {useEffect, useState} from 'react'
import Locator from "../Locator";
import WebsocketController from "../WebsocketController";
import StreamerHeaderComponent from "../components/StandardWeb/StreamerHeaderComponent";
import GameOptionComponent from "../components/StandardWeb/GameOptionComponent";
import GlobalMessageBus from "../GlobalMessageBus";
import GameVotingComponent from "../components/StandardWeb/GameVotingComponent";
import GameOptions from "../protocol/data/GameOptions";
import Voting from "../protocol/data/Voting";
import DirectAction from "../protocol/data/DirectAction";

const StreamerOptionsPage = (p:{streamerName:string, viewerId:string}) =>
{
    const [selectedActionIndex, setSelectedActionIndex] = useState(-1);
    const [playerToGameOptions, setPlayerToGameOptions] = useState(new Map<string, GameOptions>());
    const [playerCash, setPlayerCash] = useState(Locator.Model.CurrentCash);

    const OnStreamersDataReceived = () => {
        console.log("Game options updated while streamer page was active " + JSON.stringify(Locator.Model.LatestPlayerToGameOptions))
        setPlayerToGameOptions(Locator.Model.LatestPlayerToGameOptions);
    }

    const OnMoneyUpdateReceived = (e: Event) => {
        setPlayerCash( (e as any).detail)
    }

    function gtagEvent(eventName:string, args:any) {
        (window as any).gtag("event", eventName, args)
    }

    useEffect(() => {
        Locator.Socket.addEventListener(WebsocketController.STREAMERS_DATA_RECEIVED_EVENTS, OnStreamersDataReceived);
        Locator.Socket.addEventListener(WebsocketController.MONEY_DATA_RECEIVED_EVENTS, OnMoneyUpdateReceived );
        gtagEvent('web/stream_open', {
            'ID_streamer':  `${p.streamerName}`
        });
        setPlayerToGameOptions(Locator.Model.LatestPlayerToGameOptions);
        return function cleanup() {
            Locator.Socket.removeEventListener(WebsocketController.STREAMERS_DATA_RECEIVED_EVENTS, OnStreamersDataReceived);
            Locator.Socket.removeEventListener(WebsocketController.MONEY_DATA_RECEIVED_EVENTS, OnMoneyUpdateReceived );
        }
    }, []);

    const selectedUser = Locator.Model.SelectedStreamerId;
    if (selectedUser === "") {
        return (<div></div>);
    }

    let directActionsValue:DirectAction[] = [];
    let votingsValue:Voting[] = [];
    if ( playerToGameOptions.has(selectedUser) ) {
        const gameOptions = playerToGameOptions.get(selectedUser);
        if ( gameOptions !== undefined) {
            directActionsValue = gameOptions.DirectActions;
            votingsValue = gameOptions.Votings;
        }
    }

    let playerOptionsSelectedList = directActionsValue.map ((x, index) => <GameOptionComponent
        isDisabled={Locator.Model.CurrentCash < directActionsValue[index].SoftPrice}
        key={x.Name}
        optionName={x.Name}
        optionPrice={x.SoftPrice}
        optionImageUrl={"images/"+x.Name+".png"}
        isSelected={ selectedActionIndex === index }
        onClick={ () => {
            const action = directActionsValue[index];
            gtagEvent(`web/action_${action.Name}`, {
                'ID_streamer':  `${selectedUser}`,
                'ID_viewer':  `${p.viewerId}`,
                'soft_cost':  `${action.SoftPrice}`,
                'hard_cost':  `${action.HardPrice}`
            });
            Locator.Socket.UseGameOption(selectedUser, action.Name);
            //TODO: Time to introduce actions as an abstraction and stop polluting
            //TODO: view code with controller code
            Locator.Model.UpdateCash(Locator.Model.CurrentCash - action.SoftPrice);
            setSelectedActionIndex(index);
        } }
    />);

    //TODO: Add calculation based on StartVoting time. Filter expired votings
    let playerVotingsSelectedList = votingsValue.map ((x:Voting, index:number) => {
        console.log("Vote already cast= " + x.HasVoteBeenCast);
        return (< GameVotingComponent
            key = {x.Id.Id}
            remainingTimeSeconds = {x.VotingDurationSeconds}
            streamerId = {selectedUser}
            viewerId={p.viewerId}
            votingId = {x.Id}
            votingOptions = {x.VotingOptions}
            hasVoteBeenCast = {x.HasVoteBeenCast}
        />)
    });

    return (<div className="page page_steam">
        <StreamerHeaderComponent streamerName={p.streamerName}
                                 viewerName={p.viewerId}
                                 moneyAmount={playerCash}
                                 onBackButtonClicked={() =>
                                 {
                                     Locator.Model.SelectedStreamerId = "";
                                     Locator.GlobalMessageBus.dispatchEvent(new Event(GlobalMessageBus.BACK_TO_STREAMER_SELECTION_SCREEN));
                                 }}/>
        <div className="stream_section">
            <div className="mw">
                <div className="cont">
                    {playerVotingsSelectedList}
                    <div className="cards">
                        {playerOptionsSelectedList}
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default StreamerOptionsPage;