class DirectAction
{
    /// <summary>
    /// English name of the action.
    /// </summary>
    public Name:string = "";
    /// <summary>
    /// Description of the action.
    /// </summary>
    public Description:string = "";
    /// <summary>
    /// Price of the action - should be decided by game.
    /// </summary>
    public SoftPrice:number = 0;
    /// <summary>
    /// Price of the action (hard) - should be decided by game.
    /// </summary>
    public HardPrice:number = 0;
    /// <summary>
    /// Cooldown of the action defined in seconds.
    /// </summary>
    public CooldownSec:number = 0;
}

export default DirectAction;