import React, {useEffect, useState} from 'react'
import GameVotingOptionComponent from "./GameVotingOptionComponent";
import Locator from "../../Locator";
import VotingOption from "../../protocol/data/VotingOption";
import VotingId from "../../protocol/data/VotingId";
import TimeUtils from "../../utils/TimeUtils";

const GameVotingComponent = (p:{remainingTimeSeconds:number, votingOptions:VotingOption[],
                             streamerId:string, viewerId:string, votingId:VotingId, hasVoteBeenCast:boolean}) => {
    const [timeLeft, setTimeLeft] = useState(p.remainingTimeSeconds);
    const [voteCast, setVoteCast] = useState(p.hasVoteBeenCast);
    const _viewerId = p.viewerId;
    useEffect(() => {
        if ( timeLeft <= 0)
        {
            setVoteCast(true);
            return;
        }
        const timer = setTimeout(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return function cleanup() {
            clearTimeout(timer);
        }
    });

    function placeVote(x:VotingId) {
        gtagEvent( `web/voting`, {
            'ID_streamer':  `${p.streamerId}`,
            'ID_viewer':  `${_viewerId}`,
            'voting_option':  `${x.Id}`
        });
        Locator.Socket.PlaceVote(p.streamerId, p.votingId, x.Id);
        setVoteCast(true);
    }

    function gtagEvent(eventName:string, args:any) {
        (window as any).gtag("event", eventName, args)
    }

    const votingOptionsHtml = p.votingOptions.map ((x, index) => <GameVotingOptionComponent
        key={index}
        imageUrl={x.ImageURL}
        optionName={x.Name}
        isChecked={false}
        isDisabled={voteCast}
        onClick={() => placeVote(x)}/>);

    return (<div className="vote">
        <h5 className="h5">
            Голосование:
        </h5>

        <div className="time">
            {TimeUtils.FormatTime(timeLeft)}
        </div>

        <div className="vars_line">
            {votingOptionsHtml}
        </div>
    </div>)
}

export default GameVotingComponent;