import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import Locator from "./Locator";
import WebsocketController from "./WebsocketController";
import AppState from "./AppState";
import GlobalModel from "./GlobalModel";
import GlobalMessageBus from "./GlobalMessageBus";
import getLocalizationResources from "./Localization";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import StreamerOptionsPage from "./pages/StreamerOptionsPage";
import LoadingPage from "./pages/LoadingPage";
import StreamerSelectionPage from "./pages/StreamerSelectionPage";
import TwitchStreamerOptionsSelection from "./pages/TwitchStreamerOptionsSelection";
import GameOptions from "./protocol/data/GameOptions";

function App() {

  const OnTryToLogin = (viewerId:string, password:string, isTwitch:boolean = false) =>
  {
    console.log("Client: Requesting connection");
    Locator.Socket.addEventListener(WebsocketController.AUTHORIZATION_SUCCESSFUL_EVENT, OnAuthorizationSuccefull);
    Locator.Socket.Connect(`${Locator.ProtocolType}://${Locator.Url}:${Locator.Port}/${Locator.PathToEndpoint}`, () =>
    {
      Locator.Model.ViewerId = viewerId;
      if ( !isTwitch ) {
        Locator.Socket.Authorize(Locator.Model.ViewerId, password);
      } else {
        Locator.Socket.TwitchAuthorize(Locator.Model.ViewerId, password);
      }
    });
  }

  const OnAuthorizationSuccefull = () =>
  {
    Locator.Socket.removeEventListener(WebsocketController.AUTHORIZATION_SUCCESSFUL_EVENT, OnAuthorizationSuccefull);
    Locator.Socket.RequestOptionsForPlayers();
    console.log("Client: Requesting options for players");
  };

  const [appState, setAppState] = useState(AppState.Login);
  //https://rossbulat.medium.com/working-with-app-state-and-event-listeners-in-react-native-ffa9bba8f6b7
  const appStateRef = useRef(appState);


  const UpdateAppState = (state:AppState) =>
  {
    setAppState(state);
    appStateRef.current = state;
    console.log("Updated state " +state);
  }

  const OnStreamersDataReceived = () =>
  {
    //TODO: At some point we need to introduce normal Statemachine and not have this ugly hacks
    console.log(`"OnStreamersDataReceived: 
        Current state ${appStateRef.current}". 
        Twitch active ${Locator.IsTwitchLogin}. 
        StreamersData ${JSON.stringify(Locator.Model.LatestPlayerToGameOptions)}`);
    if (appStateRef.current !== AppState.StreamerOptionsSelection)
    {
      //TODO: Currently for test we are automatically selecting first logged user.
      if ( Locator.IsTwitchLogin ) {

        let options:Map<string, GameOptions> = Locator.Model.LatestPlayerToGameOptions;
        let firstItem = options.keys().next().value;
        console.log("First item in options " + firstItem);
        Locator.Model.SelectedStreamerId = firstItem;

        UpdateAppState(AppState.StreamerOptionsSelection);
        return;
      }
      if (Locator.Model.SelectedStreamerId === null || Locator.Model.SelectedStreamerId === '')
      {
        UpdateAppState(AppState.StreamersSelection);
      }
      else
      {
        UpdateAppState(AppState.StreamerOptionsSelection);
      }
    }
  }

  const OnAuthorizationSuccefullMessageReceived = () =>
  {
    UpdateAppState(AppState.Authorized);
    console.log(`"App state set to authorized: Current value ${appStateRef.current}"`);
  }

  const OnStreamerSelected = () =>
  {
    UpdateAppState(AppState.StreamerOptionsSelection);
    console.log(`OnStreamerSelected ${appStateRef.current}`);
  }

  const OnBackToStreamersSelection = () =>
  {
    console.log("OnBackToStreamersSelection");
    UpdateAppState(AppState.StreamersSelection);
  }

  const OnPaymentLinkReceived = (e: Event) => {
    let url = (e as any).detail;
    let w = document.defaultView;
    // @ts-ignore
    w.open(url, '_blank').focus();
  }

  const getParentUrl = () => {
    let isInIframe = (window.parent !== window),
        parentUrl = null;

    if (isInIframe) {
      parentUrl = document.referrer;
    }

    return parentUrl;
  }

  useEffect(() => {

    let resources = getLocalizationResources();
    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
          resources,
          lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
          // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
          // if you're using a language detector, do not define the lng option

          interpolation: {
            escapeValue: false // react already safes from xss
          }
        });

    Locator.GlobalMessageBus = new GlobalMessageBus();
    Locator.Model = new GlobalModel();
    Locator.Twitch = (window as any).Twitch ? (window as any).Twitch.ext : null;

    Locator.Socket = new WebsocketController(Locator.Model);
    Locator.Socket.addEventListener(WebsocketController.AUTHORIZATION_SUCCESSFUL_EVENT, OnAuthorizationSuccefullMessageReceived);
    Locator.Socket.addEventListener(WebsocketController.STREAMERS_DATA_RECEIVED_EVENTS, OnStreamersDataReceived);
    Locator.Socket.addEventListener(WebsocketController.PAYMENT_LINK_RECEIVED_EVENTS, OnPaymentLinkReceived);

    Locator.GlobalMessageBus.addEventListener(GlobalMessageBus.STREAMER_SELECTED_EVENT, OnStreamerSelected);
    Locator.GlobalMessageBus.addEventListener(GlobalMessageBus.BACK_TO_STREAMER_SELECTION_SCREEN, OnBackToStreamersSelection);

    const url = window.location.toString();
    if ( url.indexOf("streamer") !== -1)
    {
      const urlParams = url.substring(url.indexOf("?") + 1, url.length);
      const urlSearchParams = new URLSearchParams(urlParams);
      if (urlSearchParams.has("id") )
      {
        const id = urlSearchParams.get("id");
        if (id !== null) {
          Locator.Model.SelectedStreamerId = id.replace("#", '');
        }
      }
    }

    let origin = getParentUrl() != null ? getParentUrl() : window.location.hostname;
    if ( origin === "actionstream.xyz" || origin === "localhost") {
      OnTryToLogin("Viewer_" + Date.now(), "DummyPassword", false);
    }else {
      Locator.IsTwitchLogin = true;
      Locator.Twitch.actions.requestIdShare();
      Locator.Twitch.onAuthorized((auth:{token:string, userId:string})=>{
        console.log("Twitch response: " + JSON.stringify(auth));
        console.log("Twitch credentials: " + auth.token + ". UserID: " + auth.userId);
        OnTryToLogin(auth.userId, auth.token, true);
      })
    }

    return function cleanup() {
      Locator.Socket.removeEventListener(WebsocketController.AUTHORIZATION_SUCCESSFUL_EVENT, OnAuthorizationSuccefullMessageReceived);
      Locator.Socket.removeEventListener(WebsocketController.STREAMERS_DATA_RECEIVED_EVENTS, OnStreamersDataReceived);
      Locator.Socket.removeEventListener(WebsocketController.PAYMENT_LINK_RECEIVED_EVENTS, OnPaymentLinkReceived);

      Locator.GlobalMessageBus.removeEventListener(GlobalMessageBus.STREAMER_SELECTED_EVENT, OnStreamerSelected);
      Locator.GlobalMessageBus.removeEventListener(GlobalMessageBus.BACK_TO_STREAMER_SELECTION_SCREEN, OnBackToStreamersSelection);
    };
  }, []);

  console.log("Render");
  if ( appState === AppState.Login)
  {
    return (
        <div className="App">
          <LoadingPage/>
        </div>
    )
  }
  else if ( appState === AppState.Authorized )
  {
    return (
        <div className="App">
          <LoadingPage/>
        </div>
    )
  }else if ( appState === AppState.StreamersSelection)
  {
    window.history.pushState({}, '', `?page=streamer_selection`);
    return (<div className="App">
      <StreamerSelectionPage/>
    </div> )
  }
  else
  {
    if (Locator.IsTwitchLogin)
    {
      return (
          <div className="App">
            <TwitchStreamerOptionsSelection
                websocketController={Locator.Socket}
                globalModel={Locator.Model}
                selectedStreamerId={Locator.Model.SelectedStreamerId}
                streamerName={Locator.Model.SelectedStreamerId}
                viewerId={Locator.Model.ViewerId}/>
          </div>
      )
    } else {
      window.history.pushState({}, '', `?page=streamer&id=${Locator.Model.SelectedStreamerId}`);
      return (
          <div className="App">
            <StreamerOptionsPage streamerName={Locator.Model.SelectedStreamerId}
                                 viewerId={Locator.Model.ViewerId}
            />
          </div>
      )
    }
  }

}

export default App;
